import { withPageAuthRequired } from '@auth0/nextjs-auth0';
import { NextPage } from 'next';
import { Box } from '@mui/material';
import { DefaultLayout } from '@/layouts';
import { Platforms } from '@/components';
import { useFetchCookies } from '@/hooks';
import { PageLoader } from '@/components/shared/PageLoader';

const HomePage: NextPage = () => {
  const cookiesFetched = useFetchCookies();

  if (!cookiesFetched) {
    return (
      <Box sx={{ position: 'relative', top: '25rem' }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <DefaultLayout>
      <Platforms />
    </DefaultLayout>
  );
};

export default withPageAuthRequired(HomePage);
